import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Header,
  SideNavigation,
  SpaceBetween,
  Form,
  Container,
  FormField,
  Input,
  TopNavigation,
  Flashbar
} from '@cloudscape-design/components';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { DynamoDBClient, PutItemCommand } from "@aws-sdk/client-dynamodb";
import logo from './assets/logo512.png';
import '@cloudscape-design/global-styles/index.css';
import { v4 as uuidv4 } from 'uuid';

interface FormData {
  pk: string;
  reported: string;
  entity?: string; // Optional description property
  description?: string; // Optional description property
}

const App = () => {
  const [inputEntity, setInputEntityValue] = useState('');
  const [inputDescription, setInputDescriptionValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessFlashbar, setShowSuccessFlashbar] = useState(false);
  const [showErrorFlashbar, setShowErrorFlashbar] = useState(false);
  const [showSubmitFlashbar, setShowSubmitFlashbar] = useState(false);




  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setShowSubmitFlashbar(true)
    setIsSubmitting(true); // Set isSubmitting to true before the async operations
    e.preventDefault();

    // Generate a unique primary key value
    const primaryKey = uuidv4();

    // Get the current date and time
    const currentDateTime = new Date().toISOString();

    const formData: FormData = {
      pk: primaryKey,
      reported: currentDateTime
    };

    if (inputEntity !== '') {
      formData['entity'] = inputEntity
      setInputEntityValue('')
    }

    if (inputDescription !== '') {
      formData['description'] = inputDescription
      setInputDescriptionValue('')
    }

    // Get the authentication session
    const auth = await fetchAuthSession();
    const client = new DynamoDBClient({
      region: 'us-east-1',
      credentials: auth.credentials
    });
    try {
      // Write the item to the DynamoDB table
      const putItemCommand = new PutItemCommand({
        TableName: 'FMSTable', // Replace with your DynamoDB table name
        Item: {
          pk: { S: formData.pk },
          reported: { S: formData.reported },
          ...(formData.entity && { entity: { S: formData.entity } }),
          ...(formData.description && { description: { S: formData.description } })
        }
      });

      const putItemResponse = await client.send(putItemCommand);
      setIsSubmitting(false); // Set isSubmitting back to false
      setShowSuccessFlashbar(true); // Show the flashbar
      console.log('Item written to DynamoDB:', putItemResponse);
    } catch (error) {
      setIsSubmitting(false); // Set isSubmitting back to false in case of an error
      setShowErrorFlashbar(true); // Show the error flashbar
      console.error('Error writing item to DynamoDB:', error);
    }
  };

  return (
    <Router>
      <TopNavigation
        identity={{
          href: '/',
          title: 'Fault Management System',
          logo: {
            src: logo,
            alt: 'logo'
          }
        }}
        utilities={[
          {
            type: 'button',
            text: 'Go to IMS',
            href: 'https://ims.brucehow.people.aws.dev/',
            external: true,
            externalIconAriaLabel: ' (opens in a new tab)'
          },
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: 'Notifications (unread)',
            badge: true,
            disableUtilityCollapse: false
          },
          {
            type: 'menu-dropdown',
            text: 'User A',
            description: 'userA@example.com',
            iconName: 'user-profile',
            items: [
              { id: 'profile', text: 'Profile' },
              { id: 'preferences', text: 'Preferences' },
              { id: 'security', text: 'Security' },
              { id: 'signout', text: 'Sign out' }
            ]
          }
        ]}
      />
      <AppLayout
        toolsHide={true}
        navigation={
          <SideNavigation
            activeHref="/"
            items={[
              {
                type: 'section-group',
                title: 'Faults',
                items: [
                  {
                    type: 'link',
                    text: 'Manage Faults',
                    href: '/'
                  },
                  {
                    type: 'link',
                    text: 'Source Code',
                    external: true,
                    href: 'https://gitlab.aws.dev/aws-wwps-anz-proserve/vline/fms-ims-integration/'
                  }
                ]
              }
            ]}
          />
        }
        content={
          <ContentLayout>
            <Routes>
              <Route path="*" element={<Navigate replace to="/" />} />
              <Route
                path="/"
                element={
                  <SpaceBetween direction="vertical" size="m">
                    <BreadcrumbGroup
                      items={[
                        { text: 'Faults', href: '/' },
                        { text: 'Manage Faults', href: '/' }
                      ]}
                    />
                    <Header description="Effectively receieve and distribute faults to incident management system">
                      Manage Faults
                    </Header>
                    {showSuccessFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'success',
                            dismissible: true,
                            content: 'Fault has been successfully reported.',
                            onDismiss: () => setShowSuccessFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    {showErrorFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'error',
                            dismissible: true,
                            content: 'An error occurred while reporting the fault.',
                            onDismiss: () => setShowErrorFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    {showSubmitFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'info',
                            dismissible: true,
                            content: 'Submitting your fault report...',
                            onDismiss: () => setShowSubmitFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    <form onSubmit={handleSubmit}>
                      <Form
                        actions={
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" formAction="submit" disabled={isSubmitting}>
                              Submit
                            </Button>
                          </SpaceBetween>
                        }
                        header={<Header variant="h1">Fault Form</Header>}
                      >
                        <Container>
                          <SpaceBetween direction="vertical" size="l">
                            <FormField label="Entity">
                              <Input
                                value={inputEntity}
                                onChange={({ detail }) => setInputEntityValue(detail.value)}
                                placeholder="Enter entity"
                              />
                            </FormField>
                            <FormField label="Description">
                              <Input
                                value={inputDescription}
                                onChange={({ detail }) => setInputDescriptionValue(detail.value)}
                                placeholder="Enter your description"
                              />
                            </FormField>
                          </SpaceBetween>
                        </Container>
                      </Form>
                    </form>
                  </SpaceBetween>
                }
              />
            </Routes>
          </ContentLayout>
        }
      />
    </Router>
  );
};

export default App;
